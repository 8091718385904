import MedicalSpeciality from '@/statics/medicalSpeciality';
import Range from '@/statics/range';
import Trolley from '@/statics/trolley';
import { Feature, GIProcedureArea, LowerGILength, RespiratoryType } from '@/statics/scopeFilter';
import { LocaleCode } from '@/statics/locale';
import { ProcedureType, ProcedureTypeSelectOptions } from '@/statics/procedureType';

export default {
  meta: {
    /* same as meta infos in index_de.html */
    title: 'Endoskopieturm-System - Konfiguration in 5 Schritten',
    description:
      'Stellen Sie Ihren Endoskopieturm nach Ihren individuellen Bedürfnissen und Schwerpunkten zusammen und erhalten Sie die Konfiguration per E-Mail.'
  },
  locale: {
    [LocaleCode.DE]: 'Deutsch',
    [LocaleCode.EN]: 'Englisch (US)',
    [LocaleCode.EN_GB]: 'Englisch (UK)',
    [LocaleCode.ES]: 'Spanisch',
    [LocaleCode.IT]: 'Italienisch',
    [LocaleCode.NL]: 'Niederländisch',
    [LocaleCode.RU]: 'Russisch'
  },
  step: 'Schritt',
  quantity: 'Anzahl',
  shortPcsCounted: '{n} Stück',
  progressBarTitles: {
    gettingStarted: '@:gettingStarted.title',
    procedures: '@:procedures.title',
    optimize: '@:optimize.title',
    accessories: '@:accessories.title',
    request: '@:request.title'
  },
  loadingConfiguration: 'Ihre Konfiguration wird geladen...',
  gettingStarted: {
    title: 'Zu Beginn',
    startScreenHeadline: 'Konfigurieren Sie Ihren neuen Endoskopieturm in nur wenigen Schritten',
    medicalSpecialityHeadline: 'Bitte wählen Sie Ihre medizinische Fachrichtung',
    medicalSpeciality: {
      [MedicalSpeciality.GASTROENTEROLOGY]: 'Gastroenterologie',
      [MedicalSpeciality.PULMONOLOGY]: 'Pneumologie'
    },
    trolleyProcedureTypesHeadline: 'Wählen Sie den Schwerpunkt Ihrer Prozeduren',
    procedureTypeSelectOptions: {
      [ProcedureTypeSelectOptions.ADVANCED]: 'Fortgeschrittene Prozeduren und Optionen',
      [ProcedureTypeSelectOptions.GENERAL]: 'Grundlegende Prozeduren'
    }
  },
  procedures: {
    title: 'Prozeduren',
    headline:
      'Bitte wählen Sie Ihre Prozeduren und den Schwerpunkt Ihrer bevorzugten Konfiguration',
    procedureRequest: 'Für welche Prozeduren planen Sie eine Investition in neue Geräte?',
    procedureTypes: {
      [ProcedureType.DIAGNOSTIC]: 'Grundlegende Prozeduren',
      [ProcedureType.THERAPEUTIC]: 'Fortgeschrittene Prozeduren und Optionen'
    },
    errorNoProcedureSelected:
      'Bitte wählen Sie zunächst eine der unten aufgeführten Prozeduren aus.',
    selectAll: 'Alle auswählen',
    resetSelection: 'Auswahl zurücksetzen',
    hasRequirementsInfo: 'Bitte wählen Sie zunächst mindestens eine der Prozeduren aus:',
    isRequirementInfo: 'Bitte verwerfen Sie zunächst diese ausgewählten Prozeduren:',
    rangeRequest: 'Wählen Sie den Schwerpunkt Ihrer bevorzugten Konfiguration',
    range: {
      [Range.BASIC]: 'Bester Preis',
      [Range.MID_RANGE]: 'Preis und Innovation',
      [Range.INNOVATION]: 'Beste Leistung'
    }
  },
  optimize: {
    title: 'Optimierung',
    trolleys: {
      [Trolley.MAIN]: 'Endoskopieturm',
      [Trolley.GHOST]: 'Zusätzliche Elemente',
      [Trolley.ESG]: 'HF-Generatoren'
    },
    trolleyTitles: {
      [Trolley.MAIN]: 'Ihr Endoskopieturm',
      [Trolley.GHOST]: `@:optimize.trolleys.${Trolley.GHOST}`,
      [Trolley.ESG]: `@:optimize.trolleys.${Trolley.ESG}`
    },
    empty: 'Leer',
    addComponent: 'Elemente ergänzen',
    scopeGuide: 'ScopeGuide',
    zones: {
      M0: 'Monitore',
      M1: 'Künstliche Intelligenz',
      M2: 'Videoprozessoren',
      M3: 'Ultraschallprozessor',
      M4: 'Pumpen',
      M5: 'CO²-Insufflator',
      M6: 'HF-Generatoren',
      M7: 'Endoskope'
    },
    selectedCounted: '{count} ausgewählt',
    viewESG: 'HF-Generatoren',
    ghostDescription:
      'Falls nicht alle ausgewählten Elemente physisch auf Ihren Endoskopieturm passen, werden zusätzliche Elemente in diesem Abschnitt angezeigt (nur zu Visualisierungszwecken).',
    esgDescription:
      'Das ESG-300 wird mit dem Trolley TC-E300 (und optionalem Argonplasmagerät) ausgeliefert.',
    supportHintMedicalSpeciality: 'nur {supported}'
  },
  changeArticle: {
    subline: 'Ihre individuelle Konfiguration',
    headline: 'Andere Produkte auswählen',
    showDetails: 'Details anzeigen',
    scopesSelectedCounted: '{count} Endoskop ausgewählt|{count} Endoskope ausgewählt',
    currentlySelected: 'Derzeit ausgewählt:',
    productCode: 'Produkt Code',
    hasRequirementsInfo: 'Bitte wählen Sie zunächst mindestens eines der Produkte aus:',
    isRequirementInfo: 'Bitte verwerfen Sie zunächst diese ausgewählten Produkte:',
    technicalSpecifications: 'Technische Spezifikationen',
    noArticlesAvailable: 'Kein Artikel mit dem aktuell ausgewählten Videoprozessor kompatibel.',
    noScopesAvailable: 'Keine Endoskope mit dem aktuell ausgewähltem Videoprozessor kompatibel.',
    scopeGuideSubline: '3D Visualisierung des Endoskops',
    scopeGuideIntro: 'So funktioniert unsere 3D Visualisierung des Endoskops mit ScopeGuide',
    scopeGuideSummary:
      'ScopeGuide liefert ein 3D-Echtzeitbild von der Position und Form des Endoskops im Kolon.',
    scopeGuideDescription:
      'Elektromagnetische Spulen, die entlang des Einführschlauchs des jeweiligen Endoskops eingebaut sind, erzeugen ein gepulstes Magnetfeld geringer Intensität, das von einer Antenne empfangen wird. <br /> Aus den magnetischen Impulsen wird die genaue Position und Ausrichtung des Einführschlauchs berechnet, um ein dreidimensionales Bild auf dem Monitor zu erzeugen.',
    scopeGuideIncludedItems: 'Umfasst die folgenden Elemente:',
    notVisualized: 'In der Trolley-Konfiguration nicht abgebildet.'
  },
  scopeFilter: {
    area: {
      label: 'Bereich',
      items: {
        [GIProcedureArea.UPPER_GI]: 'Oberer Gastrointestinaltrakt',
        [GIProcedureArea.LOWER_GI]: 'Unterer Gastrointestinaltrakt',
        [GIProcedureArea.ERCP]: 'ERCP',
        [GIProcedureArea.ENTEROSCOPY]: 'Enteroskopie',
        [GIProcedureArea.ULTRASOUND]: 'Ultraschall'
      }
    },
    length: {
      label: 'Endoskoplänge',
      items: {
        [LowerGILength.INTERMEDIATE]: 'Mittel',
        [LowerGILength.LONG]: 'Lang'
      }
    },
    respiratoryType: {
      label: 'Endoskoptyp',
      items: {
        [RespiratoryType.BRONCHOSCOPE]: 'Bronchoskop',
        [RespiratoryType.MINIPROBE]: 'Radialer EBUS',
        [RespiratoryType.PLEURASCOPE]: 'Pleuraskop',
        [RespiratoryType.ULTRASOUND]: 'EBUS-TBNA'
      }
    },
    features: {
      label: 'Funktionen',
      items: {
        [Feature.WATER_JET]: 'Spülfunktion',
        [Feature.HQ]: 'HQ',
        [Feature.TRANSNASAL]: 'Transnasal',
        [Feature.PCF]: 'PCF'
      }
    },
    series: {
      label: 'Endoskopserie'
    }
  },
  accessories: {
    title: 'Zubehör',
    headline: 'Für ein vollständig ausgestattetes Endoskopiesystem empfehlen wir folgendes Zubehör'
  },
  request: {
    title: 'Anfrage senden',
    subline: 'Ihre Auswahl',
    headline:
      'Bitte senden Sie uns Ihre Anfrage, damit wir Ihnen ein individuelles Angebot unterbreiten können',
    areaTrolley: 'Endoskopieturm',
    areaAccessories: 'Zubehör'
  },
  success: {
    title: 'Anfrage übermittelt',
    headline: 'Vielen Dank.',
    subline:
      'Ihre Anfrage zur Konfiguration Ihres Endoskopieturms wurde erfolgreich versendet. Bitte beachten Sie, dass es bis zu fünf Minuten dauern kann, bis Sie Ihre E-Mail erhalten.<br /> Wir melden uns in Kürze bei Ihnen.',
    secondSubline: 'Mehr über EVIS X1 erfahren',
    buttonNew: 'Eine neue Konfiguration starten',
    buttonDetail: 'Jetzt mehr erfahren',
    detailAssetPath: '/assets/successScreen_bottom_en.png',
    detailLink:
      'https://www.olympus.de/medical/de/Produkte-und-L%C3%B6sungen/Produkte/Product/EVIS-X1.html'
  },
  contact: {
    subline: 'Kontakt',
    headline: 'Support anfordern',
    info: 'Benötigen Sie Support für die Konfiguration Ihres Endoskopieturms? Nehmen Sie mit uns Kontakt auf und wir melden uns in Kürze bei Ihnen!',
    success: 'Ihre Anfrage wurde erfolgreich übermittelt.<br /> Wir melden uns in Kürze bei Ihnen.',
    customerQuestionHeadline: 'Anfrage senden',
    backToConfiguration: 'Zurück zur Konfiguration'
  },
  saveConfiguration: {
    title: 'Später fortfahren',
    subtitle: 'Für später speichern',
    subline:
      'Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen einen Link, unter dem Sie Ihre Konfiguration fortsetzen können.',
    sendLink: 'Den Link an mich senden',
    copyLinkToClipboard: 'Diesen Link in die Zwischenablage kopieren',
    emailHeadline: 'Wie lautet Ihre E-Mail-Adresse?',
    writeClipboardSuccess: 'In die Zwischenablage kopiert',
    writeClipboardFailed: 'Fehler beim Kopieren in die Zwischenablage',
    successHeadline: 'Konfiguration gespeichert',
    successSubline:
      'Ihre Konfiguration wurde erfolgreich gespeichert.<br /> Sie können jederzeit fortfahren, indem Sie dem Link unten folgen oder den Link in der E-Mail, die wir Ihnen in Kürze senden werden, verwenden.'
  },
  footerUrlsLabel: {
    imprint: 'Impressum',
    termsOfUse: 'Nutzungsbedingungen',
    privacyNotice: 'Datenschutzerklärung',
    cookies: 'Cookies',
    cookiesSettings: 'Cookie-Einstellungen',
    socialMedia: 'Social Media'
  },
  contactForm: {
    country: 'Land',
    countryHeadline: 'Wählen Sie Ihr Land aus',
    customerName: 'Vor- und Nachname',
    customerNameHeadline: 'Wie lautet Ihr Name?',
    email: 'E-Mail-Adresse',
    emailHeadline: 'Wie lautet Ihre E-Mail-Adresse?',
    customerPhoneNumber: 'Telefonnummer',
    customerPhoneNumberHeadline: 'Wie lautet Ihre Telefonnummer?',
    customerCompany: 'Ihr Krankenhaus / Ihre Organisation',
    customerCompanyHeadline: 'Für welches Krankenhaus / welche Organisation arbeiten Sie?',
    customerQuestion: 'Ihre Nachricht',
    customerQuestionHeadline: 'Gibt es noch etwas, das Sie uns mitteilen möchten?',
    privacy:
      'Olympus respektiert Ihre Privatsphäre. Wie wir Ihre personenbezogenen Daten zur Bearbeitung Ihrer Anfrage verarbeiten, entnehmen Sie bitte unserer {link}.',
    checkboxRequired: '*Pflichtfelder'
  },
  actions: {
    back: 'Zurück',
    confirm: 'Bestätigen',
    continue: 'Fortfahren',
    no: 'Nein',
    save: 'Speichern',
    select: 'Auswählen',
    selectNothing: 'Nichts auswählen',
    selected: 'Ausgewählt',
    selectedNothing: 'Nichts ausgewählt',
    sendRequest: 'Anfrage senden',
    submit: 'Senden',
    yes: 'Ja'
  },
  error: {
    alert: 'Da ist etwas schiefgelaufen. Bitte versuchen Sie es später noch einmal.',
    title: 'Da ist etwas schiefgelaufen',
    subline: 'Vielen Dank für Ihr Interesse an Olympus Geräten!',
    info: 'Leider ist ein Fehler aufgetreten. Bitte warten Sie ein paar Sekunden und wiederholen Sie Ihre letzte Aktion oder aktualisieren Sie diese Seite.',
    contact: 'Nehmen Sie Kontakt auf mit unserer {0}.',
    customerService: 'Kundenbetreuung',
    refresh: 'Seite neuladen',
    retry: 'Erneut versuchen'
  },
  formData: {
    customerName: 'Name',
    customerCompany: '@:contactForm.customerCompany',
    country: 'Land',
    email: 'E-Mail',
    customerPhoneNumber: 'Telefonnummer',
    customerQuestion: 'Nachricht',
    optional: 'optional'
  },
  countryOptions: [
    { label: 'Albanien', value: 'AL' },
    { label: 'Algerien', value: 'DZ' },
    { label: 'Angola', value: 'AO' },
    { label: 'Armenien', value: 'AM' },
    { label: 'Österreich', value: 'AT' },
    { label: 'Aserbaidschan', value: 'AZ' },
    { label: 'Bahrein', value: 'BH' },
    { label: 'Weißrussland', value: 'BY' },
    { label: 'Belgien', value: 'BE' },
    { label: 'Bosnien und Herzegowina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bulgarien', value: 'BG' },
    { label: 'Kamerun', value: 'CM' },
    { label: 'Kroatien', value: 'HR' },
    { label: 'Zypern', value: 'CY' },
    { label: 'Tschechien', value: 'CZ' },
    { label: 'Dänemark', value: 'DK' },
    { label: 'Dschibuti', value: 'DJ' },
    { label: 'Ägypten', value: 'EG' },
    { label: 'Estland', value: 'EE' },
    { label: 'Äthiopien', value: 'ET' },
    { label: 'Finnland', value: 'FI' },
    { label: 'Frankreich', value: 'FR' },
    { label: 'Georgien', value: 'GE' },
    { label: 'Deutschland', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Griechenland', value: 'GR' },
    { label: 'Ungarn', value: 'HU' },
    { label: 'Island', value: 'IS' },
    { label: 'Iran', value: 'IR' },
    { label: 'Irak', value: 'IQ' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italien', value: 'IT' },
    { label: 'Elfenbeinküste', value: 'CI' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kasachstan', value: 'KZ' },
    { label: 'Kenia', value: 'KE' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kirgisistan', value: 'KG' },
    { label: 'Lettland', value: 'LV' },
    { label: 'Libanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Libyen', value: 'LY' },
    { label: 'Litauen', value: 'LT' },
    { label: 'Mazedonien', value: 'MK' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Mauretanien', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Marokko', value: 'MA' },
    { label: 'Mosambik', value: 'MZ' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Niederlande', value: 'NL' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Norwegen', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Palästina', value: 'PS' },
    { label: 'Polen', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Katar', value: 'QA' },
    { label: 'Republik Kongo', value: 'CG' },
    { label: 'Rumänien', value: 'RO' },
    { label: 'Russland', value: 'RU' },
    { label: 'Ruanda', value: 'RW' },
    { label: 'Saudi-Arabien', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbien & Montenegro', value: 'RS' },
    { label: 'Seychellen', value: 'SC' },
    { label: 'Slowakei', value: 'SK' },
    { label: 'Slowenien', value: 'SI' },
    { label: 'Somalia', value: 'SO' },
    { label: 'Republik von Südafrika', value: 'ZA' },
    { label: 'Südsudan', value: 'SS' },
    { label: 'Spanien', value: 'ES' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Swasiland', value: 'SZ' },
    { label: 'Schweden', value: 'SE' },
    { label: 'Schweiz', value: 'CH' },
    { label: 'Syrien', value: 'SY' },
    { label: 'Tansania', value: 'TZ' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tunesien', value: 'TN' },
    { label: 'Türkei', value: 'TC' },
    { label: 'Vereinigtes Königreich & Irland', value: 'GB' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'Vereinigte Arabische Emirate', value: 'AE' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Sambia', value: 'ZM' },
    { label: 'Simbabwe', value: 'ZW' }
  ]
};
